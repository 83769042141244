<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">门店类型</h3>
    </div>
    <div class="box">
      <div class="titles">
        <el-button size="small" type="primary" @click="dialogShopType = true"
          >添加分类</el-button
        >
        <div class="ipts">
          <p>
            <span>类型名称:</span
            ><input
              v-model="listForm.name"
              placeholder="请输入名称"
              type="text"
            />
          </p>
          <p>
            <el-radio v-model="listForm.status" :label="1">开启</el-radio>
            <el-radio v-model="listForm.status" :label="2">禁用</el-radio>
            <el-radio v-model="listForm.status" :label="0">全部</el-radio>
            <el-button size="mini" type="success" @click="shaixuan"
              >筛选</el-button
            >
          </p>
        </div>
      </div>
      <div class="tables">
        <el-table :data="list" border stripe>
          <el-table-column prop="id" label="分类id"> </el-table-column>
          <el-table-column prop="name" label="分类名称"> </el-table-column>
          <el-table-column label="创建时间">
            <div slot-scope="scope">
              {{ scope.row.createtime | datefilter }}
            </div>
          </el-table-column>
          <el-table-column label="分类状态">
            <div slot-scope="scope">
              <el-tag v-if="scope.row.status == 1" size="mini" type="success">启用</el-tag>
              <el-tag v-else size="mini" type="info">禁用</el-tag>
              <span class="xiugai shou" @click="SetTypeStatus(scope.row)">修改</span>
            </div>
          </el-table-column>
          <el-table-column label="操作">
            <div slot-scope="scope">
              <el-button
                size="mini"
                round
                type="primary"
                @click="bianji(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                round
                type="danger"
                @click="deleteType(scope.row.id)"
                >删除</el-button
              >
            </div>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 20px"
          @current-change="typeListCrtChg"
          :current-page="listForm.page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加编辑类型 -->
    <el-dialog title="门店分类" width="30%" :visible.sync="dialogShopType">
      <el-form :rules="rules" :model="ShopTypeform" ref="addshoptype">
        <el-form-item label="名称:" prop="name">
          <el-input
            v-model="ShopTypeform.name"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="list_order" style="margin-left: 15px" label="排序:">
          <el-input
            v-model="ShopTypeform.list_order"
            placeholder="请输入分类排序"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-radio v-model="ShopTypeform.status" :label="1">正常</el-radio>
          <el-radio v-model="ShopTypeform.status" :label="2">禁用</el-radio>
        </el-form-item>
        <el-form-item label="备注:" prop="remark">
          <el-input v-model="ShopTypeform.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogShopType = false">取 消</el-button>
        <el-button type="primary" @click="addType">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  shoptypelist,
  addShopType,
  delShopType,
  shopTypeDetail,
  shopTypeStatus
} from "@/api/api.js";
export default {
  components: {},
  name: "",
  data() {
    return {
      listForm: {
        name: "",
        status: 0,
        page: 1,
        limit: 10,
      },
      total: 1,
      list: [],

      //  添加编辑分类
      dialogShopType: false,
      ShopTypeform: {
        name: "",
        list_order: "",
        status: 1,
        remark: "",
      },
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        remark: [],
        list_order: [],
        status: [],
      },
    };
  },
  watch: {
    dialogShopType: function (newData, oldData) {
      if (!newData) {
        delete this.ShopTypeform.id;
        this.ShopTypeform.name = "";
        this.ShopTypeform.list_order = "";
        this.ShopTypeform.status = 1;
        this.ShopTypeform.remark = "";
      }
    },
  },
  methods: {
    async SetTypeStatus(item) {
        let id = item.id;
        let status = item.status == 1 ? 2 : 1;
        const {data} = await shopTypeStatus({id, status})
        if(data.code != 200) return this.$message.error(data.data)
        this.$message.success(data.data)
        item.status = status
    },
    deleteType(id) {
      this.$confirm("真的要删除该分类吗?, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delShopType({ id });
          if (data.code != 200) return this.$message.error(data.data);
          this.list.forEach((item) => {
            if (item.id == id) {
              let index = this.list.indexOf(item);
              this.list.splice(index, 1);
            }
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async bianji(item) {
      const { data } = await shopTypeDetail({ id: item.id });
      this.ShopTypeform.id = data.data.id;
      this.ShopTypeform.name = data.data.name;
      this.ShopTypeform.list_order = data.data.list_order;
      this.ShopTypeform.status = data.data.status;
      this.ShopTypeform.remark = data.data.remark;
      this.dialogShopType = true;
    },
    addType() {
      this.$refs["addshoptype"].validate(async (valid) => {
        if (valid) {
          const { data } = await addShopType(this.ShopTypeform);
          if (data.code != 200) return this.$message.error(data.data);
          this.$message.success(data.data);
          this.dialogShopType = false;
          this.getlist();
        } else {
          this.$message.warning("请检查填写内容!");
          return false;
        }
      });
    },
    async getlist() {
      const { data } = await shoptypelist(this.listForm);
      if (data.code != 200) return this.$message.error(data.data);
      this.list = data.data.data;
      this.total = data.data.total;
    },
    typeListCrtChg(e) {
      this.listForm.page = e;
      this.getlist();
    },
    shaixuan() {
      this.listForm.page = 1;
      this.getlist();
    },
  },
  created() {
    this.getlist();
  },
};
</script>

<style scoped lang='less'>
.titles {
  background-color: rgb(241, 243, 244);
  padding: 10px;
  p {
    font-size: 14px;
    margin: 10px 0;
    span {
      margin-right: 5px;
    }
    input {
      height: 30px;
    }
  }
}
.tables {
  margin: 20px;
}
.el-dialog {
  .el-input {
    width: 50%;
  }
}
.xiugai {
    color: #999;
    border-bottom:  1px solid #999;
    margin-left: 12px;
}
</style>